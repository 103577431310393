import { call, put, select, takeLatest } from 'redux-saga/effects'
import { NotificationManager } from 'react-notifications'
import api from 'util/Api'
import {
  Types as SystemTypes,
  Creators as SystemActions,
} from 'store/ducks/System'
import { Creators as EquipamentActions } from 'store/ducks/Equipament'

export function* listSystems({ payload }) {
  try {
    const params = payload || {}
    const response = yield call(api.get, '/systems', { params })
    yield put(SystemActions.listSystemsSuccess(response.data))
  } catch (error) {
    console.error('Erro ao listar sistemas:', error)
    NotificationManager.error('Erro ao listar sistemas.')
    yield put(
      SystemActions.listSystemsFailure(
        error.response?.data?.message || 'Erro ao listar sistemas',
      ),
    )
  }
}

export function* getSystemId({ payload }) {
  try {
    const response = yield call(api.get, `/systems/${payload}`)
    yield put(SystemActions.getSystemIdSuccess(response.data))
  } catch (error) {
    console.error('Erro ao obter sistema.', error)
    NotificationManager.error('Erro ao obter sistema.')
    yield put(
      SystemActions.getSystemIdFailure(
        error.response?.data?.message || 'Erro ao obter sistema.',
      ),
    )
  }
}

export function* createSystem({ payload }) {
  try {
    const response = yield call(api.post, '/systems', payload)

    NotificationManager.success('Sistema criado com sucesso.')

    yield put(SystemActions.createSystemSuccess(response.data))

    const { list } = yield select(state => state.systems)
    const updatedList = [...list.data, response.data]
    yield put(SystemActions.listSystemsSuccess(updatedList))

    const { data: equipmentData } = yield select(state => state.equipament)
    const updatedEquipmentList = equipmentData?.map(equipment => ({
      ...equipment,
      system_id: response.data.equipments.some(e => e.id === equipment.id)
        ? response.data.id
        : equipment.system_id,
      system: response.data.equipments.some(e => e.id === equipment.id)
        ? {
            id: response.data.id,
            name: response.data.name,
            order: response.data.order,
            site_id: response.data.site_id,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at,
          }
        : equipment.system,
    }))

    yield put(EquipamentActions.listEquipamentSuccess(updatedEquipmentList))
  } catch (error) {
    console.error('Erro ao criar sistema:', error)
    NotificationManager.error('Erro ao criar sistema.')
    yield put(
      SystemActions.createSystemFailure(
        error.response?.data?.message || 'Erro ao criar sistema',
      ),
    )
  }
}

export function* updateSystem({ payload }) {
  try {
    const { id, data } = payload
    const response = yield call(api.put, `/systems/${id}`, data)

    NotificationManager.success('Sistema atualizado com sucesso.')

    yield put(SystemActions.updateSystemSuccess())

    const { list } = yield select(state => state.systems)
    const updatedList = list.data.map(system =>
      system.id === id ? response.data : system,
    )
    yield put(SystemActions.listSystemsSuccess(updatedList))

    const { data: equipmentData } = yield select(state => state.equipament)
    const updatedEquipmentList = equipmentData?.map(equipment => {
      const isLinkedToUpdatedSystem = response.data.equipments.some(
        e => e.id === equipment.id,
      )

      if (isLinkedToUpdatedSystem) {
        return {
          ...equipment,
          system_id: response.data.id,
          system: {
            id: response.data.id,
            name: response.data.name,
            order: response.data.order,
            site_id: response.data.site_id,
            created_at: response.data.created_at,
            updated_at: response.data.updated_at,
          },
        }
      }

      if (equipment.system_id === id) {
        return { ...equipment, system_id: null, system: null }
      }

      return equipment
    })

    yield put(EquipamentActions.listEquipamentSuccess(updatedEquipmentList))
  } catch (error) {
    console.error('Erro ao atualizar sistema:', error)
    NotificationManager.error('Erro ao atualizar sistema.')
    yield put(
      SystemActions.updateSystemFailure(
        error.response?.data?.message || 'Erro ao atualizar sistema',
      ),
    )
  }
}

export function* deleteSystem({ payload }) {
  try {
    yield call(api.delete, `/systems/${payload}`)

    NotificationManager.success('Sistema deletado com sucesso.')

    yield put(SystemActions.deleteSystemSuccess())

    const { list } = yield select(state => state.systems)
    const updatedList = list.data.filter(system => system.id !== payload)
    yield put(SystemActions.listSystemsSuccess(updatedList))

    const { data: equipmentData } = yield select(state => state.equipament)
    const updatedEquipmentList = equipmentData?.map(equipment => {
      if (equipment.system && equipment.system.id === payload) {
        return { ...equipment, system: null }
      }
      return equipment
    })
    yield put(EquipamentActions.listEquipamentSuccess(updatedEquipmentList))
  } catch (error) {
    console.error('Erro ao deletar sistema:', error)
    NotificationManager.error('Erro ao deletar sistema.')
    yield put(
      SystemActions.deleteSystemFailure(
        error.response?.data?.message || 'Erro ao deletar sistema',
      ),
    )
  }
}

export default function* systemSagas() {
  yield takeLatest(SystemTypes.LIST_SYSTEMS_REQUEST, listSystems)
  yield takeLatest(SystemTypes.GET_SYSTEM_ID_REQUEST, getSystemId)
  yield takeLatest(SystemTypes.CREATE_SYSTEM_REQUEST, createSystem)
  yield takeLatest(SystemTypes.UPDATE_SYSTEM_REQUEST, updateSystem)
  yield takeLatest(SystemTypes.DELETE_SYSTEM_REQUEST, deleteSystem)
}
