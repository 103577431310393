import React from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'

const InjectMessage = ({ id, intl }) => {
  const message =
    id && intl.messages[id] ? intl.formatMessage({ id }) : id || id

  return <>{message}</>
}

export default injectIntl(InjectMessage)

export const getSafeIntlMessage = (intl, id, defaultMessage = '') => {
  if (!id) return defaultMessage
  return intl.messages[id] ? intl.formatMessage({ id }) : id
}
