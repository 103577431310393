import React from 'react'
import IntlMessages from 'util/IntlMessages'
import TextField from '@material-ui/core/TextField'
import { Controller } from 'react-hook-form'
import { withStyles } from '@material-ui/styles'
import { MenuItem } from '@material-ui/core'
import MaskedInput from 'react-text-mask'
import { phoneMask } from 'util/index'

const styles = () => ({
  input: {
    maxHeight: '3px !important',
    color: '#495057',
    fontWeight: 400,
    fontFamily: 'inherit',
  },
  multilineInput: {
    paddingTop: 10,
    color: '#495057',
    fontWeight: 400,
    fontFamily: 'inherit',
  },
  disabled: {
    opacity: 0.6,
  },
  cssOutlinedInput: {
    paddingTop: '0px !important',
    backgroundColor: '#f5f5f5',
    '&$cssFocused $notchedOutline': {
      borderColor: '#3f51b5 !important',
      borderWidth: '1px',
    },
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '1px',
    borderColor: '#ced4da !important',
  },
  select: {
    padding: 0,
    paddingTop: '12px',
    paddingBottom: '25px',
    paddingLeft: '15px',
  },
  selectMenu: {
    minHeight: '0px !important',
  },
})

class FormInput extends React.Component {
  render() {
    const {
      name,
      label,
      placeholder,
      errors,
      type,
      labelClassName,
      labelStyle,
      mask,
      control,
      defaultValue,
      items,
      disabled,
      customOnChange,
      handleCurrency,
      setValue,
      onKeyPress,
      value,
      multiline,
      tooltip,
    } = this.props
    const { classes } = this.props

    const renderInput = () => {
      if (mask) {
        return (
          <Controller
            name={name || label}
            control={control}
            defaultValue
            render={({ ref, value, onChange }) => (
              <MaskedInput
                mask={mask === 'phone' ? phoneMask : mask}
                value={String(value)}
                onChange={e => onChange(e.target.value)}
                ref={ref}
                render={(refNew, propsMask) => (
                  <TextField
                    {...propsMask}
                    onKeyPress={onKeyPress}
                    type={type || 'text'}
                    placeholder={placeholder}
                    disabled={disabled}
                    variant="outlined"
                    inputRef={refNew}
                    SelectProps={{
                      classes: {
                        select: classes.select,
                        selectMenu: classes.selectMenu,
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                        input: classes.input,
                        disabled: disabled && classes.disabled,
                      },
                    }}
                  />
                )}
              />
            )}
          />
        )
      }

      return (
        <Controller
          name={name || label}
          control={control}
          render={({ ref, value, onChange }) => (
            <>
              <TextField
                select={!!items}
                type={type || 'text'}
                onKeyPress={onKeyPress}
                defaultValue={defaultValue}
                value={value}
                placeholder={placeholder}
                onChange={e =>
                  customOnChange
                    ? customOnChange(e.target.value)
                    : onChange(e.target.value)
                }
                disabled={disabled}
                variant="outlined"
                onBlur={e =>
                  handleCurrency &&
                  handleCurrency(e.target.value, name, setValue)
                }
                onFocus={() =>
                  handleCurrency &&
                  setValue(name, value?.toString().replace(/[^0-9,]/g, ''))
                }
                inputRef={ref}
                SelectProps={{
                  classes: {
                    select: classes.select,
                    selectMenu: classes.selectMenu,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                    input: multiline ? classes.multilineInput : classes.input,
                    disabled: disabled && classes.disabled,
                  },
                }}
                multiline={!!multiline}
                rowsMax={4}
              >
                {items &&
                  items?.map(item => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </TextField>
            </>
          )}
        />
      )
    }

    const renderInputWithoutControll = () => {
      if (mask) {
        return (
          <MaskedInput
            mask={mask === 'phone' ? phoneMask : mask}
            value={value}
            defaultValue={defaultValue}
            onChange={e => customOnChange(e.target.value)}
            render={(refNew, propsMask) => (
              <TextField
                {...propsMask}
                onKeyPress={onKeyPress}
                type={type || 'text'}
                placeholder={placeholder}
                disabled={disabled}
                variant="outlined"
                inputRef={refNew}
                SelectProps={{
                  classes: {
                    select: classes.select,
                    selectMenu: classes.selectMenu,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                    input: classes.input,
                    disabled: disabled && classes.disabled,
                  },
                }}
              />
            )}
          />
        )
      }

      return (
        <TextField
          select={!!items}
          type={type || 'text'}
          onKeyPress={onKeyPress}
          defaultValue={defaultValue}
          value={value}
          placeholder={placeholder}
          onChange={e => customOnChange(e.target.value)}
          disabled={disabled}
          variant="outlined"
          onBlur={e =>
            handleCurrency && handleCurrency(e.target.value, name, setValue)
          }
          onFocus={() =>
            handleCurrency &&
            setValue(name, value?.toString().replace(/[^0-9,]/g, ''))
          }
          SelectProps={{
            classes: {
              select: classes.select,
              selectMenu: classes.selectMenu,
            },
          }}
          InputProps={{
            classes: {
              root: classes.cssOutlinedInput,
              focused: classes.cssFocused,
              notchedOutline: classes.notchedOutline,
              input: classes.input,
              disabled: disabled && classes.disabled,
            },
          }}
        >
          {items &&
            items?.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </TextField>
      )
    }

    return (
      <>
        {label && (
          <h4
            className={labelClassName || ''}
            style={labelStyle || { opacity: 0.8 }}
          >
            <IntlMessages id={label} />
            {tooltip && tooltip}
          </h4>
        )}
        <div className="d-flex flex-column w-100">
          {control ? renderInput() : renderInputWithoutControll()}
          {errors && (
            <p style={{ color: 'red' }}>
              {name ? errors?.[name]?.message : errors?.[label]?.message}
            </p>
          )}
        </div>
      </>
    )
  }
}

export default withStyles(styles)(FormInput)
