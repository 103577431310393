import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, takeLatest, select } from 'redux-saga/effects'
import {
  Types as EquipamentTypes,
  Creators as EquipamentActions,
} from 'store/ducks/Equipament'
import { Creators as SystemActions } from 'store/ducks/System'
import Api from 'util/Api'
import { parseError } from 'util/index'

function* createEquipamentSagas({ payload }) {
  try {
    const { status, data } = yield call(Api.post, '/equipments', payload)
    if (status === 200) {
      NotificationManager.success('Equipamento cadastrado com sucesso.')
      yield put(EquipamentActions.createEquipamentSuccess(data))
    } else {
      parseError(data || 'Falha ao cadastrar equipament.')
      yield put(EquipamentActions.createEquipamentError())
    }
  } catch (err) {
    parseError(err || 'Falha ao cadastrar equipament.')
    yield put(EquipamentActions.createEquipamentError())
  }
}

function* listEquipamentsSagas({ payload }) {
  const params = payload
  try {
    const { status, data } = yield call(Api.get, '/equipments', {
      params,
    })
    if (status === 200) {
      yield put(EquipamentActions.listEquipamentSuccess(data))
    } else {
      parseError(data || 'Falha ao listar equipament.')
      yield put(EquipamentActions.listEquipamentError())
    }
  } catch (err) {
    parseError(err || 'Falha ao listar equipament.')
    yield put(EquipamentActions.listEquipamentError())
  }
}

function* getEquipamentIdSagas({ payload }) {
  const { id, params } = payload
  try {
    const { status, data } = yield call(Api.get, `/equipments/${id}`, {
      params: { with: 'roles,templates,customer', ...params },
    })
    if (status === 200) {
      yield put(EquipamentActions.getEquipamentIdSuccess(data))
    } else {
      parseError(data || 'Falha ao carregar equipament.')
      yield put(EquipamentActions.getEquipamentIdError())
    }
  } catch (err) {
    parseError(err || 'Falha ao carregar equipament.')
    yield put(EquipamentActions.getEquipamentIdError())
  }
}

function* editEquipamentSagas({ payload }) {
  const { id, params } = payload
  try {
    const { status, data } = yield call(Api.put, `/equipments/${id}`, params)
    if (status === 200) {
      NotificationManager.success('Equipamento editado com sucesso.')
      yield put(EquipamentActions.editEquipamentSuccess(data))
    } else {
      parseError(data || 'Erro ao editar equipamento.')
      yield put(EquipamentActions.editEquipamentError())
    }
  } catch (err) {
    parseError(err || 'Erro ao editar equipamento.')
    yield put(EquipamentActions.editEquipamentError())
  }
}

function* deleteEquipamentSagas({ payload }) {
  try {
    const { data: equipments } = yield select(state => state.equipament)
    const { status, data } = yield call(Api.delete, `/equipments/${payload}`)
    if (status === 200 || status === 204) {
      const updatedEquipments = equipments?.filter(item => item.id !== payload)
      yield put(EquipamentActions.listEquipamentSuccess(updatedEquipments))
      yield put(EquipamentActions.deleteEquipamentSuccess())
      NotificationManager.success('Equipamento deletado com sucesso.')

      const { list } = yield select(state => state.systems)
      const updatedSystems = list.data?.map(system => ({
        ...system,
        equipments: system.equipments.filter(equip => equip.id !== payload),
      }))

      yield put(SystemActions.listSystemsSuccess(updatedSystems))
    } else {
      parseError(data || 'Erro ao deletar equipamento.')
      yield put(EquipamentActions.deleteEquipamentError())
    }
  } catch (err) {
    parseError(err || 'Erro ao deletar equipamento.')
    yield put(EquipamentActions.deleteEquipamentError())
  }
}

function* importVariable({ payload }) {
  try {
    const { data, status } = yield call(
      Api.post,
      `/equipments/import/${payload.id}`,
      payload.data,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      },
    )
    if (status === 200) {
      NotificationManager.success('Variável importada com sucesso.')
      yield put(EquipamentActions.importVariableEquipmentSuccess())
    } else {
      parseError(data || 'Erro ao importar variável.')
      yield put(EquipamentActions.importVariableEquipmentFail(data))
    }
  } catch (err) {
    parseError(err || 'Erro ao importar variável.')
    yield put(
      EquipamentActions.importVariableEquipmentFail(err?.response?.data),
    )
  }
}

function* getDashboard({ payload }) {
  try {
    const { data, status } = yield call(
      Api.get,
      `/equipments/all/dashboard/${payload?.id ? payload.id : ''}`,
      payload,
    )
    if (status === 200) {
      yield put(EquipamentActions.getDashBoardEquipmentSuccess(data))
    } else {
      parseError(data || 'Erro ao importar variável.')
      yield put(EquipamentActions.getDashboardEquipmentFail(data))
    }
  } catch (err) {
    parseError(err || 'Erro ao importar variável.')
    yield put(EquipamentActions.getDashboardEquipmentFail(err?.response?.data))
  }
}

function* getHasIcon({ payload }) {
  try {
    const { data, status } = yield call(
      Api.get,
      `/equipments/hasIcon/${payload}`,
    )
    if (status === 200) {
      yield put(EquipamentActions.getHasIconEquipmentSuccess(data))
    } else {
      parseError(data || 'Erro ao listar os icones.')
      yield put(EquipamentActions.getHasIconEquipmentFail(data))
    }
  } catch (err) {
    parseError(err || 'Erro ao listar os icones.')
    yield put(EquipamentActions.getHasIconEquipmentFail(err?.response?.data))
  }
}

function* forceReadEquipamentSagas({ payload }) {
  try {
    const { status, data } = yield call(
      Api.get,
      `/equipments/${payload}/force-read`,
    )
    if (status === 200) {
      yield put(EquipamentActions.forceReadEquipmentSuccess())
      NotificationManager.success('Forçamento de leitura iniciado, aguarde...')
    } else {
      parseError(data || 'Erro ao forçar leitura.')
      yield put(EquipamentActions.forceReadEquipmentError())
    }
  } catch (err) {
    parseError(err || 'Erro ao forçar leitura.')
    yield put(EquipamentActions.forceReadEquipmentError())
  }
}

function* watchCreateEquipament() {
  yield takeLatest(
    EquipamentTypes.CREATE_EQUIPAMENT_REQUEST,
    createEquipamentSagas,
  )
}

function* watchListEquipaments() {
  yield takeLatest(
    EquipamentTypes.LIST_EQUIPAMENTS_REQUEST,
    listEquipamentsSagas,
  )
}

function* watchGetEquipamentId() {
  yield takeLatest(
    EquipamentTypes.GET_EQUIPAMENT_ID_REQUEST,
    getEquipamentIdSagas,
  )
}

function* watchEditEquipament() {
  yield takeLatest(EquipamentTypes.EDIT_EQUIPAMENT_REQUEST, editEquipamentSagas)
}

function* watchDeleteEquipament() {
  yield takeLatest(
    EquipamentTypes.DELETE_EQUIPAMENT_REQUEST,
    deleteEquipamentSagas,
  )
}

function* importVariableWatcher() {
  yield takeLatest(
    EquipamentTypes.IMPORT_VARIABLE_EQUIPMENT_REQUEST,
    importVariable,
  )
}

function* getDashboardWatcher() {
  yield takeLatest(
    EquipamentTypes.GET_DASHBOARD_EQUIPMENT_REQUEST,
    getDashboard,
  )
}

function* getHasIconWatcher() {
  yield takeLatest(EquipamentTypes.GET_HAS_ICON_EQUIPMENT_REQUEST, getHasIcon)
}

function* forceReadEquipamentWatcher() {
  yield takeLatest(
    EquipamentTypes.FORCE_READ_EQUIPMENT_REQUEST,
    forceReadEquipamentSagas,
  )
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateEquipament),
    fork(watchListEquipaments),
    fork(watchEditEquipament),
    fork(watchDeleteEquipament),
    fork(watchGetEquipamentId),
    fork(importVariableWatcher),
    fork(getDashboardWatcher),
    fork(getHasIconWatcher),
    fork(forceReadEquipamentWatcher),
  ])
}
