import { NotificationManager } from 'react-notifications'
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import {
  Types as GasLackTypes,
  Creators as GasLackActions,
} from 'store/ducks/GasLackInsight'
import api from 'util/Api'
import { parseError } from 'util/index'

function* getGasLack({ payload }) {
  try {
    const { data, status } = yield call(api.get, '/insights/gasLack', payload)
    if (status === 200) {
      yield put(GasLackActions.getGasLackSuccess(data))
    } else {
      parseError(data || 'Erro ae listar o insight.')
      yield put(GasLackActions.getGasLackFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae listar o insight.')
    yield put(GasLackActions.getGasLackFail())
  }
}

function* postGasLack({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/insights/gasLack', payload)
    if (status === 200) {
      NotificationManager.success('Insight criado com sucesso.')
      yield put(GasLackActions.postGasLackSuccess())

      const { data: gasLackData } = yield select(state => state.gasLack)
      const updatedList = [...gasLackData, data]
      yield put(GasLackActions.getGasLackSuccess(updatedList))
    } else {
      parseError(data || 'Erro ao criar o insight.')
      yield put(GasLackActions.postGasLackFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao criar o insight.')
    yield put(GasLackActions.postGasLackFail())
  }
}

function* showGasLack({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/insights/gasLack/${payload}`)
    if (status === 200) {
      yield put(GasLackActions.showGasLackSuccess(data))
    } else {
      parseError(data || 'Erro ao carregar o insight.')
      yield put(GasLackActions.showGasLackFail())
    }
  } catch (err) {
    parseError(err || 'Erro ao carregar o insight.')
    yield put(GasLackActions.showGasLackFail())
  }
}

function* updateGasLack({ payload }) {
  try {
    const { data, status } = yield call(
      api.put,
      `/insights/gasLack/${payload.id}`,
      payload.data,
    )
    if (status === 200) {
      NotificationManager.success('Insight atualizado com sucesso.')
      yield put(GasLackActions.updateGasLackSuccess())

      const { data: gasLackData } = yield select(state => state.gasLack)
      const updatedList = gasLackData?.map(gasLack =>
        gasLack.id === payload.id ? data : gasLack,
      )
      yield put(GasLackActions.getGasLackSuccess(updatedList))
    } else {
      parseError(data || 'Erro ae atualizar o insight.')
      yield put(GasLackActions.updateGasLackFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae atualizar o insight.')
    yield put(GasLackActions.updateGasLackFail())
  }
}

function* deleteGasLack({ payload }) {
  try {
    const { data, status } = yield call(
      api.delete,
      `/insights/gasLack/${payload}`,
    )
    if (status === 200 || status === 204) {
      yield put(GasLackActions.deleteGasLackSuccess())
      NotificationManager.success('Insight deletado com sucesso.')

      const { data } = yield select(state => state.gasLack)
      const updatedList = data?.filter(gasLack => gasLack.id !== payload)
      yield put(GasLackActions.getGasLackSuccess(updatedList))
    } else {
      parseError(data || 'Erro ae deletar o insight.')
      yield put(GasLackActions.deleteGasLackFail())
    }
  } catch (err) {
    parseError(err || 'Erro ae deletar o insight.')
    yield put(GasLackActions.deleteGasLackFail())
  }
}

function* getGasLackWatcher() {
  yield takeLatest(GasLackTypes.GET_GAS_LACK_REQUEST, getGasLack)
}

function* postGasLackWatcher() {
  yield takeLatest(GasLackTypes.POST_GAS_LACK_REQUEST, postGasLack)
}

function* showGasLackWatcher() {
  yield takeLatest(GasLackTypes.SHOW_GAS_LACK_REQUEST, showGasLack)
}

function* updateGasLackWatcher() {
  yield takeLatest(GasLackTypes.UPDATE_GAS_LACK_REQUEST, updateGasLack)
}

function* deleteGasLackWatcher() {
  yield takeLatest(GasLackTypes.DELETE_GAS_LACK_REQUEST, deleteGasLack)
}

export default function* rootSaga() {
  yield all([
    fork(getGasLackWatcher),
    fork(postGasLackWatcher),
    fork(showGasLackWatcher),
    fork(updateGasLackWatcher),
    fork(deleteGasLackWatcher),
  ])
}
