export const Types = {
  LIST_SYSTEMS_REQUEST: 'SYSTEMS/LIST_SYSTEMS_REQUEST',
  LIST_SYSTEMS_SUCCESS: 'SYSTEMS/LIST_SYSTEMS_SUCCESS',
  LIST_SYSTEMS_FAILURE: 'SYSTEMS/LIST_SYSTEMS_FAILURE',

  GET_SYSTEM_ID_REQUEST: 'SYSTEMS/GET_SYSTEM_ID_REQUEST',
  GET_SYSTEM_ID_SUCCESS: 'SYSTEMS/GET_SYSTEM_ID_SUCCESS',
  GET_SYSTEM_ID_FAILURE: 'SYSTEMS/GET_SYSTEM_ID_FAILURE',
  GET_SYSTEM_ID_CLEAN: 'SYSTEMS/GET_SYSTEM_ID_CLEAN',

  CREATE_SYSTEM_REQUEST: 'SYSTEMS/CREATE_SYSTEM_REQUEST',
  CREATE_SYSTEM_SUCCESS: 'SYSTEMS/CREATE_SYSTEM_SUCCESS',
  CREATE_SYSTEM_FAILURE: 'SYSTEMS/CREATE_SYSTEM_FAILURE',
  CREATE_SYSTEM_CLEAN: 'SYSTEMS/CREATE_SYSTEM_CLEAN',

  UPDATE_SYSTEM_REQUEST: 'SYSTEMS/UPDATE_SYSTEM_REQUEST',
  UPDATE_SYSTEM_SUCCESS: 'SYSTEMS/UPDATE_SYSTEM_SUCCESS',
  UPDATE_SYSTEM_FAILURE: 'SYSTEMS/UPDATE_SYSTEM_FAILURE',
  UPDATE_SYSTEM_CLEAN: 'SYSTEMS/UPDATE_SYSTEM_CLEAN',

  DELETE_SYSTEM_REQUEST: 'SYSTEMS/DELETE_SYSTEM_REQUEST',
  DELETE_SYSTEM_SUCCESS: 'SYSTEMS/DELETE_SYSTEM_SUCCESS',
  DELETE_SYSTEM_FAILURE: 'SYSTEMS/DELETE_SYSTEM_FAILURE',
  DELETE_SYSTEM_CLEAN: 'SYSTEMS/DELETE_SYSTEM_CLEAN',

  SYSTEMS_STATE_CLEAN: 'SYSTEMS/SYSTEMS_STATE_CLEAN',
}

const INITIAL_STATE = {
  list: { data: [], loading: false, success: false, error: null },
  get: {
    data: null,
    loading: false,
    success: false,
    error: null,
  },
  create: {
    loading: false,
    success: false,
    error: null,
  },
  update: {
    loading: false,
    success: false,
    error: null,
  },
  delete: {
    loading: false,
    success: false,
    error: null,
  },
}

const systemReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case Types.SYSTEMS_STATE_CLEAN:
      return { ...INITIAL_STATE }

    case Types.LIST_SYSTEMS_REQUEST:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
          error: null,
        },
      }
    case Types.LIST_SYSTEMS_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.payload,
          loading: false,
          success: true,
        },
      }
    case Types.LIST_SYSTEMS_FAILURE:
      return {
        ...state,
        list: {
          ...state.list,
          data: [],
          loading: false,
          success: false,
          error: action.payload.error,
        },
      }

    case Types.GET_SYSTEM_ID_REQUEST:
      return {
        ...state,
        get: {
          ...state.get,
          loading: true,
          error: null,
        },
      }
    case Types.GET_SYSTEM_ID_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          data: action.payload,
          loading: false,
          success: true,
        },
      }
    case Types.GET_SYSTEM_ID_FAILURE:
      return {
        ...state,
        get: {
          ...state.get,
          data: null,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      }
    case Types.GET_SYSTEM_ID_CLEAN:
      return { ...state, get: INITIAL_STATE.get }

    case Types.CREATE_SYSTEM_REQUEST:
      return {
        ...state,
        create: {
          ...state.create,
          loading: true,
          error: null,
        },
      }

    case Types.CREATE_SYSTEM_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: true,
          data: action.payload,
        },
      }

    case Types.CREATE_SYSTEM_FAILURE:
      return {
        ...state,
        create: {
          ...state.create,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      }

    case Types.CREATE_SYSTEM_CLEAN:
      return { ...state, create: INITIAL_STATE.create }

    case Types.UPDATE_SYSTEM_REQUEST:
      return {
        ...state,
        update: {
          ...state.update,
          loading: true,
          error: null,
        },
      }

    case Types.UPDATE_SYSTEM_SUCCESS:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: true,
        },
      }

    case Types.UPDATE_SYSTEM_FAILURE:
      return {
        ...state,
        update: {
          ...state.update,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      }

    case Types.UPDATE_SYSTEM_CLEAN:
      return { ...state, update: INITIAL_STATE.update }

    case Types.DELETE_SYSTEM_REQUEST:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: true,
          error: null,
        },
      }

    case Types.DELETE_SYSTEM_SUCCESS:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          success: true,
        },
      }

    case Types.DELETE_SYSTEM_FAILURE:
      return {
        ...state,
        delete: {
          ...state.delete,
          loading: false,
          success: false,
          error: action.payload.error,
        },
      }

    case Types.DELETE_SYSTEM_CLEAN:
      return { ...state, delete: INITIAL_STATE.delete }

    default:
      return state
  }
}

export const Creators = {
  listSystemsRequest: filters => ({
    type: Types.LIST_SYSTEMS_REQUEST,
    payload: filters,
  }),
  listSystemsSuccess: payload => ({
    type: Types.LIST_SYSTEMS_SUCCESS,
    payload,
  }),
  listSystemsFailure: error => ({
    type: Types.LIST_SYSTEMS_FAILURE,
    payload: { error },
  }),

  getSystemIdRequest: id => ({
    type: Types.GET_SYSTEM_ID_REQUEST,
    payload: id,
  }),

  getSystemIdSuccess: payload => ({
    type: Types.GET_SYSTEM_ID_SUCCESS,
    payload,
  }),

  getSystemIdFailure: error => ({
    type: Types.GET_SYSTEM_ID_FAILURE,
    payload: { error },
  }),

  getSystemIdClean: () => ({
    type: Types.GET_SYSTEM_ID_CLEAN,
  }),

  createSystemRequest: payload => ({
    type: Types.CREATE_SYSTEM_REQUEST,
    payload,
  }),
  createSystemSuccess: payload => ({
    type: Types.CREATE_SYSTEM_SUCCESS,
    payload,
  }),
  createSystemFailure: error => ({
    type: Types.CREATE_SYSTEM_FAILURE,
    payload: { error },
  }),

  updateSystemRequest: payload => ({
    type: Types.UPDATE_SYSTEM_REQUEST,
    payload,
  }),
  updateSystemSuccess: () => ({
    type: Types.UPDATE_SYSTEM_SUCCESS,
  }),
  updateSystemFailure: error => ({
    type: Types.UPDATE_SYSTEM_FAILURE,
    payload: { error },
  }),

  deleteSystemRequest: id => ({
    type: Types.DELETE_SYSTEM_REQUEST,
    payload: id,
  }),
  deleteSystemSuccess: () => ({
    type: Types.DELETE_SYSTEM_SUCCESS,
  }),
  deleteSystemFailure: error => ({
    type: Types.DELETE_SYSTEM_FAILURE,
    payload: { error },
  }),

  createSystemClean: () => ({ type: Types.CREATE_SYSTEM_CLEAN }),
  updateSystemClean: () => ({ type: Types.UPDATE_SYSTEM_CLEAN }),
  deleteSystemClean: () => ({ type: Types.DELETE_SYSTEM_CLEAN }),
  systemsStateClean: () => ({
    type: Types.SYSTEMS_STATE_CLEAN,
  }),
}

export default systemReducer
